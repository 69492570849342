import { DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import moment from "moment";
import { Moment } from "moment";

let checkPending: any;
export function emailValidator(rule, value, callback): void {
  clearTimeout(checkPending);
  if (!value) {
    return callback();
  }
  checkPending = setTimeout(() => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(value).toLowerCase())) {
      callback();
    } else {
      callback("Format email tidak sesuai");
    }
  }, 500);
}

export function phoneValidator(rule, value, callback): void {
  const regex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g);
  if (regex.test(value)) {
    callback();
  } else if (!value) {
    callback();
  } else {
    callback(
      "Format phone doesn't match , Make sure it follows the rule above"
    );
  }
}
export function phoneValidatorv2(rule, value, callback): void {
  const regex = new RegExp(/^.{9,15}$/);
  if (regex.test(value)) {
    callback();
  } else if (!value) {
    callback();
  } else {
    callback(
      "Format phone doesn't match , Make sure it follows the rule above"
    );
  }
}

export function numericOnly(value) {
  return value.replace(/[^0-9\\.]+/g, "");
}

export function numericdotOnly(rule, value, callback): void {
  const regex = new RegExp(/^[0-9.\b]+$/);
  if (regex.test(value)) {
    callback();
  } else if (!value) {
    callback();
  } else {
    callback("Please input numeric characters only");
  }
}

export function changeCurrencytoNumeric(value) {
  if (value) {
    if (value.includes(",")) return Number(value.replace(/,/g, ""));
    else return Number(value);
  } else return Number(value);
}

export function currencyFormat(value) {
  if (value) {
    if (value.toString().includes(","))
      return Number(value.toString().replace(/,/g, ""))
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    else
      return Number(value)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  } else {
    return "";
  }
}

export function currencyFormatDot(value) {
  if (value) {
    return Number(value).toFixed(2);
  } else {
    return "";
  }
}
export function checkPasswordStrength(rule, value, callback): void {
  const regex = new RegExp(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
  );
  if (regex.test(value)) {
    callback();
  } else if (!value) {
    callback();
  } else {
    callback(
      "Your password is not strong enough. Make sure it follows the rules above"
    );
  }
}

export function checkTaxNumber(rule, value, callback): void {
  if (value) {
    if (!value.includes("-") || !value.includes(".")) {
      const regex = new RegExp(/^[0-9]{15}$/);
      if (regex.test(value)) callback();
      else if (!value) callback();
      else callback("Invalid format tax number");
    } else {
      let numb = value.match(/\d/g);
      numb = numb.join("");
      if (numb.length === 15) callback();
      else callback("Invalid format tax number");
    }
  } else callback("Invalid format tax number");
}

export function formatTaxCode(value) {
  if (value)
    return (
      `${value.toString().substring(0, 3)}` +
      "." +
      `${value.toString().substring(3, 5)}` +
      "." +
      `${value.toString().substring(5, 13)}`
    );
  else return "";
}
export function formatTaxCodetoNumber(value) {
  if (value) {
    if (value.includes(".")) return Number(value.split(".").join(""));
    else return Number(value);
  } else {
    return Number(value);
  }
}

export function formatTaxNumber(value) {
  if (value)
    return (
      `${value.toString().substring(0, 2)}` +
      "." +
      `${value.toString().substring(2, 5)}` +
      "." +
      `${value.toString().substring(5, 8)}` +
      "." +
      `${value.toString().substring(8, 9)}` +
      "-" +
      `${value.toString().substring(9, 12)}` +
      "." +
      `${value.toString().substring(12, 15)}`
    );
  else return "";
}
export function formatTaxNumbertoNumber(value) {
  if (value) {
    if (value.includes("-"))
      return Number(
        value
          .split(".")
          .join("")
          .split("-")
          .join("")
      );
    else return Number(value);
  } else {
    return Number(value);
  }
}

export const formatCurrency = (input: string): string => {
  let dt: number;
  if (input) {
    if (input.includes(",")) {
      dt = parseInt(input.replace(/,/g, ""));
    } else {
      dt = parseInt(input);
    }
    return dt
      .toFixed(DECIMAL_PLACES_CURRENCY)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }
  return input;
};

export const letterKeydown = (e): void => {
  if (
    e.keyCode != 46 &&
    e.keyCode != 45 &&
    e.keyCode > 31 &&
    (e.keyCode < 48 || e.keyCode > 57)
  ) {
    e.preventDefault();
  }
};

export const formatterNumber = (value: any) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const reverseFormatNumber = (value: any) => {
  return value.replace(/(,*)/g, "");
};

export const formatterPercent = (value: number | string) => {
  return `${value}%`;
};

export const reverseFormatPercent = (value: number | string) => {
  if (typeof value === "string") {
    return value.replace("%", "");
  } else {
    return value;
  }
};

export function isDateAfterMaxDate(
  value: Moment | Date | number | string,
  maxDate = new Date()
): boolean {
  return moment(value).isAfter(maxDate);
}

/**
 * do validation on given Array Of Object (JSON)
 * @param dataSource data table
 * @param keys columns that need to be valid
 * @returns {Object} validateTable.colValid determine if some data invalid
 * @returns {Object} validateTable.messages return messages and row index
 * @returns {Array} validateTable.messages[0] column label validation
 * @returns {Array} validateTable.messages[1] row position at given rows
 */
export const validateTable = (
  dataSource: { [key: string]: any }[],
  keys: { key: string; message: string }[]
): { colValid: boolean; messages: [string, number] } => {
  let flag = true;
  const returnVal = {
    colValid: true,
    messages: ["", -1] as [string, number],
  };
  for (let i = 0; i < dataSource.length; i++) {
    if (!flag) break;
    const el = dataSource[i];
    for (const [key, value] of Object.entries(el)) {
      if (!flag) break;
      for (let j = 0; j < keys.length; j++) {
        if (keys[j].key === key && !value) {
          flag = false;
          returnVal.colValid = false;
          // `required column ${keys[j].message} at row ${i + 1}`;
          returnVal.messages = [keys[j].message, i + 1];
          break;
        }
      }
    }
  }
  return returnVal;
};

export const hasInvalidFilename = (fileName: string): boolean => {
  // only allow characters, numeric, underscore, and hypens
  const exp = /^[A-Za-z0-9 ._-]+$/;

  return !exp.test(fileName);
};
